<template>
  <div class="choiceness-wrap">
    <!--精选头像-->
    <img :src="item.avatar" alt="0" class="choiceness-avatar"/>

    <!--精选信息-->
    <div class="choiceness-info">
      <el-row :gutter="0">
        <el-col :span="10" class="name">
          <el-tooltip :content="item.name">
            <span>{{ item.name.length > 3 ? item.name.substring(0, 3) : item.name }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="3" class="sex">{{ item.sex === 1 ? '男' : '女' }}</el-col>
        <el-col :span="11">
          <el-tag size="small" type="warning"> {{ item.level }}</el-tag>
          <!--          <span class="label">{{ item.level }}</span>-->
        </el-col>
      </el-row>
      <!--item.profile.length > 48 ? item.profile.substring(0, 43) + '...' : item.profile-->
      <div slot="reference" class="desc" v-html="item.profile"></div>

      <div class="footer">
        <span class="service-time">服务人数:{{ item.countPeople }}次</span>
        <el-button size="mini" type="primary" @click="openFeaturedSidebar(item)">详情</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  props: ['item'],
  computed: {
    ...mapState(['featuredShow'])
  },
  methods: {
    ...mapMutations(['openFeaturedSidebar'])
  }
}
</script>

<style lang="scss" scoped>
.choiceness-wrap {
  box-sizing: border-box;
  padding: 17px 13px 16px 22px;
  display: flex;
  width: 100%;
  height: 174px;
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 6px;
}

.choiceness-avatar {
  width: 117px;
  height: 139px;
  background: rgb(0, 0, 0);
  opacity: 1;
  border-radius: 106px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.sex {
  font-size: 18px;
  color: #4E4E4E;
}

.label {
  background: rgba(218, 181, 78, 0.19);
  border-radius: 100px;
  color: #DA864E;
  width: 3em;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  display: block;
  margin: auto;
}

.el-tag {
  margin: 0;
}

.desc {
  margin-top: 14px;
  font-size: 14px;
  color: #8A8A8A;
  word-break: break-all;
  line-height: 17px;
  height: 51px;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .service-time {
    font-size: 12px;
    color: #B5B5B5;
  }

  .detail-btn {
    width: 78px;
    height: 32px;
    background: #4E6FDA;
    border-radius: 5px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }

}

.choiceness-info {
  flex: 1;
  margin-left: 13px;
}

.el-col:not(:first-child) {
  text-align: center;
}
</style>
