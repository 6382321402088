<template>
  <div>
    <el-dialog
        title=""
        :visible.sync="loginShow"
        width="460px"
        @close="close" :close-on-click-modal="false">

      <div class="login">
        <div class="login-box">
          <span class="login-box-title">登录</span>
          <span class="login-box-sub-title">欢迎您，来到咨询系统！</span>
          <el-form :model="form">
            <el-form-item prop="username">
              <el-input v-model="form.username" prefix-icon="el-icon-user" />
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="form.password" @keyup.enter.native="handleLogin" prefix-icon="el-icon-suitcase" type="password" />
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="handleLogin">登录</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { goLogin, getUserInfo } from "../api/home";
import {mapMutations, mapState, mapActions} from 'vuex';
import { formatPwd } from '@utils';

export default {
  data() {
    // a189f8145cba4baae036cad1b332d0cd
    // A189F8145CBA4BAAE036CAD1B332D0CD
    // A189F8145CBA4BAAE036CAD1B332D0CD
    return {
      isShow: false,
      form: {
        username: '',
        password: '',
        grant_type: 'password',
        client_id: 'admin-app',
        client_secret: 'B1538757C07E6A6AD2D4F71A4986E6B6',
        relogin: 1
      }
    }
  },
  computed: {
    ...mapState(['loginShow'])
  },
  methods: {
    ...mapMutations([
      'setToken',
      'showLoginPopup',
      'closeLoginPopup',
      'setUserIcloseLoginPopupnfo'
    ]),
    ...mapActions([
        'handleUserInfo'
    ]),
    open() {
      // this.isShow = true
      this.showLoginPopup();
    },
    close(){
      this.closeLoginPopup()
    },
    // 登录
    handleLogin() {
      const params = { ...this.form};
      // 加盐
      params.password = formatPwd(params.password);

      goLogin(params).then(res => {

        if (res.code === 200) {
          // 存入token
          this.setToken(res.data.accessToken);

          // 获取用户信息
          this.handleUserInfo();

          this.closeLoginPopup();

          this.$router.push('/');
          setTimeout(()=>{
            location.reload()
          },500)

        }
      }).catch(reason => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  display: flex;
  justify-content: center;
}

.login {
  width: 420px;
  //height: 100vh;
  display: flex;

  &-box {
    width: 576px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    opacity: 1;
    border-radius: 10px;
    padding: 30px 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #4E6FDA;
      margin-bottom: 14px;
      letter-spacing: 5px;
    }

    &-sub-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #808080;
      margin-bottom: 48px;
    }

    .el-form {
    }

    .el-button {
      width: 240px;
      margin: 20px auto 0;
    }
  }
}
</style>
