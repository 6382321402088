<template>
    <div class="home-wrap">
      <!--头部-->
      <header v-if="!$store.state.token">
        <div class="header-wrap">
          <h2>咨询系统</h2>
          <div>你好,&nbsp;&nbsp;请&nbsp;&nbsp;<span @click="goLogin">登录</span></div>
        </div>
      </header>

      <!--内容-->
      <main>
        <!--banner-->
        <!-- <img class="banner" src="@/assets/banner@2x.png" alt="0" /> -->

        <!--精选咨询-->
        <div class="counsel-wrap">
          <div class="counsel-title">
            <!-- <img src="@/assets/sectorIcon@2x.png" alt="0"/> -->
            <!-- <h3>精选咨询</h3> -->
            <!-- <img src="@/assets/sectorIcon@2x.png" alt="0"/> -->
          </div>
          <div class="counsel-list">
            <CounselorCard :item="counselList[0]" />
          </div>
        </div>

        <!--精选咨询师-->
        <!-- <div class="counselor-wrap">
          <div class="counsel-title">
            <img src="@/assets/sectorIcon@2x.png" alt="0"/>
            <h3>精选咨询师</h3>
            <img src="@/assets/sectorIcon@2x.png" alt="0"/>
          </div>
          <div class="counselor-list">
            <ChoicenessCounselor v-for="item in counselorList" :item="item" :key="item.id" />
          </div>
        </div> -->
      </main>

      <!--弹窗-->
      <LoginPopup ref="login" />

      <!--右侧咨询师详情弹窗-->
      <FeaturedCounselorDetails />
    </div>
</template>

<script>
import CounselorCard from "../components/CounselorCard";
import ChoicenessCounselor from "../components/ChoicenessCounselor";
import LoginPopup from "../components/LoginPopup";
import { getFeaturedCounsel, getFeaturedCounselor } from '@/api/home';
import FeaturedCounselorDetails from "../components/FeaturedCounselorDetails";
export default {
  name: "home",
  data() {
    return {
      counselorList: [],
      counselList: []
    }
  },
  components: {
    CounselorCard,
    ChoicenessCounselor,
    LoginPopup,
    FeaturedCounselorDetails
  },
  created() {
    this.featuredCounsel();
    this.featuredCounselor();
  },
  mounted() {
  },
  methods: {
    goLogin() {
      this.$refs.login.open()
    },

    //  精选咨询
    featuredCounsel() {
      getFeaturedCounsel({
        featured: 1
      }).then(({ data }) => {
        if (data) {
          this.counselList = data.slice(0, 4)
        }

      })
    },

    // 精选咨询师
    featuredCounselor() {
      getFeaturedCounselor().then(({ data }) => {
        this.counselorList = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-wrap {
  // background: #f6f6f6;
  padding-bottom: 75px;
}

header {
  // width: 100%;
  background: #FFFFFF;

  .header-wrap {
    box-sizing: border-box;
    padding: 0 17px 0 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    height: 87px;

    h2 {
      font-size: 26px;
      color: #4E6FDA;
    }

    div {
      font-size: 16px;
      color: #272727;

      span {
        display: inline-block;
        width: 74px;
        height: 30px;
        background: #4E6FDA;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}

main {
  width: 1200px;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }

  .counsel-wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      margin: 0 11px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.counsel-title {
  margin-bottom: 26px;
  display: flex;
  align-items: center;

  img {
    width: 10px;
    height: 10px;
  }
}

.counselor-wrap {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 0 11px;
    font-size: 20px;
    font-weight: bold;
  }
}

.counsel-list {
  // display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 20px;
  grid-column-gap: 16px;
  width: 100%;
}

.counselor-list {
  display: grid;
  grid-template-columns:33.33% 33.33% 33.33%;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  width: 100%;
}
</style>
