<template>
  <el-drawer :show-close="false" :visible="featuredShow" size="500px" @close="closeFeaturedSidebar">
    <div class="box" v-if="featuredDetails">
      <div class="user-info">
        <img class="avatar" :src="featuredDetails.avatar" alt="0" />
        <div class="info">
          <div class="top">
            <div>
              <span class="text-title text-simple">{{ featuredDetails.name }}</span>
              <span class="text-simple text-desc">出生年月：{{ formatDate(featuredDetails.birthday, 'YYYY.MM.DD') }}</span>
            </div>
            <img v-show="featuredDetails.sex === 1" src="@/assets/man@2x.png" alt="0" />
            <img v-show="featuredDetails.sex === 2" src="@/assets/woman@2x.png" alt="0" />
          </div>
          <div class="bottom">
            <el-tag plain size="mini" type="primary">累计时长：{{ featuredDetails.countTime }}分钟</el-tag>
            <el-tag plain size="mini" type="danger">咨询次数：{{ featuredDetails.countPeople }}次</el-tag>
          </div>
        </div>
      </div>
      <el-row>
        <span>简介：</span>
        <div style="word-break: break-word; line-height: 20px" v-html="featuredDetails.profile">
        </div>
      </el-row>
      <!--<el-row>-->
      <!--  <el-col :span="20">-->
      <!--    <span>顾客年龄</span>-->
      <!--  </el-col>-->
      <!--  <el-col :span="4">-->
      <!--    <span>{{ featuredDetails.age }}</span>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row>-->
      <!--  <el-col :span="12">-->
      <!--    <span>最近咨询时间</span>-->
      <!--  </el-col>-->
      <!--  <el-col :span="12">-->
      <!--    <span>{{ formatDate(featuredDetails.consultTime, 'YYYY-MM-DD HH:mm') }}</span>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <p class="title">他的服务</p>
      <ul>
        <li v-for="item in featuredDetails.contents" :key="item.id" @click="handleFeatured(item.id)">
          <el-card>
            <div class="card-body">
              <img alt="0" :src="item.serviceImage" />
              <div>
                <el-row align="middle" class="tags" type="flex">
                  <el-col :span="8">
                    <span class="text-title text-simple">{{
                        item.consultantName.length > 3 ? item.consultantName.slice(0, 3) + '...' : item.consultantName
                      }}</span>
                  </el-col>
                  <el-col :span="8">
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="item.serviceName">
                    <el-tag slot="reference" circle size="mini" type="primary">{{
                        item.serviceName.length > 5 ? item.serviceName.slice(0, 5) + '...' : item.serviceName
                      }}</el-tag>
                    </el-popover>
                  </el-col>
                  <el-col :span="8">
                    <el-tag circle size="mini" type="warning">{{ item.level }}</el-tag>
                  </el-col>
                </el-row>

                <el-popover
                    placement="top-start"
                    title="标题"
                    width="200"
                    trigger="hover"
                    :content="item.serviceContent">
                  <div class="desc text-desc" slot="reference">
                    {{ item.serviceContent.length > 82 ? item.serviceContent.slice(0, 82) + '...' : item.serviceContent }}
                  </div>
                </el-popover>


                <el-rate :value="item.score" disabled></el-rate>
                <div class="bottom">
                  <span class="text-desc">
                    ￥<span>{{ item.price }}</span>
                  </span>
                  <!--<el-button round disabled size="mini" type="info">已咨询</el-button>-->
                  <el-button round v-if="item.status === 2" size="small" type="warning">正在咨询</el-button>
                  <el-button round v-if="item.status === 3" size="small" type="info">咨询已结束</el-button>
                  <el-button round v-if="item.status === 1" size="small" type="info">未咨询</el-button>
                </div>
              </div>
            </div>
          </el-card>
        </li>
      </ul>
    </div>
    <div class="footer">
      <el-button type="primary" @click="closeFeaturedSidebar">关闭</el-button>
    </div>
  </el-drawer>
</template>

<script>
const dayjs = require('dayjs');
import { mapState,mapMutations } from 'vuex';
export default {
  name: "FeaturedCounselorDetails",
  data() {
    return {
      show: false,
      rate: 4,
      detail: null
    }
  },
  created() {

  },
  watch:{
    featuredShow(){
      console.log('当前的数据：', this.featuredDetails)
    },
  },
  computed: {
    ...mapState(['featuredShow', 'featuredDetails'])
  },
  methods: {
    ...mapMutations(['closeFeaturedSidebar', 'showLoginPopup']),
    open(item) {
      this.detail = item;
      this.show = true;
    },
    handleClose() {
      this.show = false
    },

    // 过滤时间
    formatDate(time, format) {
      return dayjs(time).format(format)
    },

    handleFeatured(id) {
      if (this.$store.state.token) {
        this.$router.push(`consultDetails?id=${id}`)
      } else {
        this.showLoginPopup();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}

.user-info {
  display: flex;
  border-bottom: 1px solid #E4E7ED;
  padding-bottom: 20px;

  .avatar {
    width: 88px;
    height: 104px;
    background: rgba(0, 0, 0, 1);
    border-radius: 106px;
    margin-right: 20px;
  }

  .info {
    width: calc(100% - 108px);

    .top {
      display: flex;
      justify-items: center;

      & > div {
        flex: 4;

      }

      .text-desc {
        margin: 15px 0;
      }

      img {
        width: 39px;
        height: 39px;
      }

      .icon-nv {
        color: $woman-color;
      }
    }


  }
}

.el-row:not(.tags) {
  line-height: 60px;
  border-bottom: 1px solid #E4E7ED;
  font-size: 14px;
  color: #272727;

  .el-col:last-child {
    text-align: right;
  }
}

.title {
  margin-top: 40px;
  font-size: 18px;
  font-family: PingFang SC;
  line-height: 25px;
  color: $theme-color;
}

.video-box {
  height: 250px;
  background: #131313;
}

.subtitle {
  margin: 20px 0;
}

li {
  margin-bottom: 10px;
}

::v-deep .el-drawer__body {

  padding-bottom: 105px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #909399;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131313;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #434343;
  }
}

.el-drawer {
  position: relative;
}

.footer {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
}

.card-body {
  height: 176px;
  opacity: 1;
  border-radius: 6px;
  display: flex;
  padding: 13px 0;
  box-sizing: border-box;

  img {
    width: 125px;
    margin-right: 10px;
    height: 150px;
    object-fit: cover;
  }

  & > div {
    width: calc(100% - 135px);

    .desc {
      margin: 10px 0;
    }
  }
}

.el-tag {
  margin-top: 0;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  .text-desc {
    span {
      font-size: 18px;
      font-weight: bold;
      color: $theme-color;
    }
  }
}
</style>
